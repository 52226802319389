import React from "react";
import { useNavigate } from 'react-router-dom';
import './Guarantee.scss'

import help1 from './imgs/help1.jpg'
import help2 from './imgs/help2.jpg'
import help3 from './imgs/help3.jpg'
import help4 from './imgs/help4.jpg'
import help5 from './imgs/help5.jpg'
import help6 from './imgs/help6.jpg'
import help7 from './imgs/help7.jpg'
import help8 from './imgs/help8.jpg'

export const Guarantee = () => {
    const navigate = useNavigate()

    const handleNavigate = (e) => {
        navigate(e.target.id)
        window.scrollTo({
            top: 0,
            // behavior: 'smooth'
        })
    }

    return (
        <div className="MainContainer MBInfo">
            <div className="BreadCrumbs">
                <span className="LastCrumb" id="/" onClick={handleNavigate}>Главная</span>
                <span className="SlashCrumb">/</span>
                <span className="NewCrumb">Гарантия оригинальности</span>
            </div>
            <div className="GuaranteeInfo">
                <h2>KICKSIE - гарантия оригинальности</h2>
                <div className="GradientText">
                    <span className="GradientVerticalLine"></span>
                    <p>
                        Заказывая обувь или одежду через нас, вы можете не сомневаться
                        в оригинальности заказанного айтема, и сейчас мы вам это докажем.
                    </p>
                </div>
                <p className="MB42">
                    Главная особенность нашего магазина в том, что заказ клиента едет с маркетплейса
                    с собственным профессиональным легит-чек центром, который детально проверяет каждую
                    вещь на оригинальность, а так же подтверждает собственными пломбами и сертификатами,
                    что проверка пройдена успешно, тем самым гарантируя клиенту, что он получит оригинальную
                    продукцию исключительно с официальных заводов.
                </p>
                <div className="GuaranteeImg MB42">
                    <img src={help1} alt="" />
                </div>
                <h2>Более подробно о процессе легит-чека.</h2>
                <p>
                    Казалось бы, все просто, но на самом деле товар проходит довольно много процессов, включая:
                    приемку - контроль качества - фотографирование - идентификацию - защиту от подделок - перепроверку
                    и другие процессы для завершения идентификации и выпуска продукта.
                </p>
                <p>
                    Товар сначала доставляется на склад Poizon, где проходит проверку на оригинальность, а так же мелкие дефекты:
                </p>
                <p className="GListItem">
                    &nbsp;• В случае обнаружения реплики товар возвращают продавцу и навсегда запрещают ему осуществлять деятельность на платформе Poizon.
                </p>
                <p className="GListItem MB42">
                    &nbsp;• В случае обнаружения дефекта менеджеры Poizon запрашивают у продавца замену. В виду этого клиенты всегда получают оригинальный товар без каких-либо дефектов.
                </p>
                <h2>Инспекция брака и дефектов</h2>
                <p>Коробка с обувью проверяется на отсутствие повреждений внутри и снаружи; форма обуви соответствует; верх, язычок и шнурки тщательно осматриваются.</p>
                <div className="GuaranteeImg MB16">
                    <img src={help2} alt="" />
                </div>
                <p className="MB42">
                    При наличии незначительных дефектов будут сделаны и зафиксированы фотографии,
                    а также заранее до отправки будет проведена индивидуальная беседа с заказчиком.
                </p>
                <h2>Аутентификация является изюминкой</h2>
                <p>
                    Для каждой категории и марки существуют профессиональные знатоки, которые знают,
                    что они делают, чтобы определить оригинальную вещь.
                </p>
                <div className="GuaranteeImg MB16">
                    <img src={help3} alt="" />
                </div>
                <p>
                    В случае кроссовок, например, маркировка размера, прострочка, клей для чистки стельки,
                    стальной отпечаток на обувной коробке и боковая маркировка обувной коробки - все это важные этапы процесса аутентификации.
                </p>
                <div className="GuaranteeImg MB16">
                    <img src={help4} alt="" />
                </div>
                <p className="MB42">
                    Аутентификация одежды, ярлык на воротнике, ярлык для стирки, процесс вышивки и т.д. - все это важные звенья.
                </p>
                <h2>Оптический анализ</h2>
                <p>
                    Это оптический анализ под ультрафиолетовым светом:
                </p>
                <div className="GuaranteeImg MB16">
                    <img src={help5} alt="" />
                </div>
                <p className="MB42">
                    Области носка кроссовка по-разному реагируют на флуоресценцию, слева - подделка, справа - настоящий.
                </p>
                <h2>Повторная проверка</h2>
                <p>
                    Перед упаковкой проверяется качество товара, сканируется QR-код на защитной застежке и идентификационный сертификат для подтверждения соответствия.
                </p>
                <div className="GuaranteeImg MB42">
                    <img src={help6} alt="" />
                </div>
                <h2>Защита от подделок</h2>
                <p className="MB0">
                    Продукт, прошедший проверку подлинности, будет сопровождаться сертификатом подлинности и эксклюзивной застежкой против подделок.
                </p>
                <p>
                    Уникальный номер и QR-код на пряжке и сертификате являются подтверждением идентификации.
                </p>
                <div className="GuaranteeImg MB42">
                    <img src={help7} alt="" />
                </div>
                <h2>Сертификат подлинности</h2>
                <p>
                    Сертификат подлинности является секретом, и при сканировании он представляет модель AR купленного кроссовка, что очень круто!
                </p>
                <div className="GuaranteeImg2 MB42">
                    <img src={help8} alt="" />
                </div>
                <h2>Почему же у нас настолько низкие цены?</h2>
                <p>
                    Порой разница между РФ рынком и ценой в Китае может достигать 80%. Это происходит в виду 2х причин:
                </p>
                <p className="GListItem MB0">
                    1. Большинство заводов-производителей расположены в Китае, как производящих материалы, так и саму одежду.
                </p>
                <p className="GListItem">
                    2. Высокая наценка у отечественных продавцов, вследствие высоких издержек на логистике, аренды помещений, так и элементарной жадности.
                </p>
            </div>
        </div>
    )
}