import React from "react";
import { useNavigate } from 'react-router-dom';
import './Help.scss'

export const Help = () => {
    const navigate = useNavigate()

    const handleNavigate = (e) => {
        navigate(e.target.id)
        window.scrollTo({
            top: 0,
        })
    }

    return (
        <div className="MainContainer MBInfo">
            <div className="BreadCrumbs">
                <span className="LastCrumb" id="/" onClick={handleNavigate}>Главная</span>
                <span className="SlashCrumb">/</span>
                <span className="NewCrumb">Помощь</span>
            </div>
            <div className="HelpInfo">
                <h2>Что такое POIZON и как происходит процесс легит-чека</h2>
                <div className="PoizonIs">
                    <span>
                        <b>POIZON</b> - маркетплейс, работающий на территории Китая и осуществляющий деятельность по продаже брендовой одежды.
                        Существует уже более 5 лет. Его альтернативы: StockX, Farfetch.
                    </span>
                </div>
                <p className="MBHelp">
                    Главная особенность Poizon в собственном профессиональном легит-чек центре, 
                    который детально проверяет каждую вещь на оригинальность, а так же подтверждает 
                    собственными пломбами и сертификатами, что проверка пройдена успешно, тем самым гарантируя 
                    клиенту, что он получит оригинальную продукцию исключительно с официальных заводов.
                </p>
                <div className="HelpImg" />
                <h2 className="MTHelp">Более подробно о процессе легит-чека Poizon</h2>
                <p>
                    Казалось бы, все просто, но на самом деле товар проходит довольно много процессов, 
                    включая: приемку - контроль качества - фотографирование - идентификацию - защиту 
                    от подделок - перепроверку и другие процессы для завершения идентификации и выпуска продукта...
                </p>
                <p>Товар сначала доставляется на склад Poizon, где проходит проверку на оригинальность, а так же мелкие дефекты:</p>
                <p className="HListItem">&nbsp;• В случае обнаружения реплики товар возвращают продавцу и навсегда запрещают ему осуществлять деятельность на платформе Poizon.</p>
                <p className="HListItem MB0">&nbsp;• В случае обнаружения дефекта менеджеры Poizon запрашивают у продавца замену. В виду этого клиенты всегда получают оригинальный товар без каких-либо дефектов.</p>
                <h2 className="MTHelp">Инспекция брака и дефектов</h2>
                <p>Коробка с обувью проверяется на отсутствие повреждений внутри и снаружи; форма обуви соответствует; верх, язычок и шнурки тщательно осматриваются.</p>
                <div className="HelpImg" />
            </div>
        </div>
    )
}